import React from 'react'

import './imgWithDescription.less'

export default function NewsImgWithDescription(props) {
    const img = require(`../../assets/news/img/${props.img}`)

    return (
        <div className="news_img_with_descr">
            <img className="news_img_with_descr__img" src={img.default} alt="" />
            <div className="news_img_with_descr__text">
                <small>{props.description}</small>
            </div>
        </div>
    )
}
