import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/code/src/components/news/layout.jsx";
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SEO from '../../components/seo';
import NewsHeader from '../../components/news/header';
import NewsImgWithDescription from '../../components/news/imgWithDescription';
import NewsImg from '../../components/news/img';
export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEO title={"Airvoice has verified Air Quality data from AirQuality.report and agreed on project integration into the  AirVoice Platform"} description={"Airvoice and EQOlabs (AirQuality.report service) have signed an agreement which will make representative Air Quality Information available on AirVoice open map"} lang={useTranslation().language} link={useI18next().path} mdxType="SEO" />
    <NewsHeader tag="news" title="Airvoice has verified Air Quality data from AirQuality.report and agreed on project integration into the  AirVoice Platform" description="Airvoice and EQOlabs (AirQuality.report service) have signed an agreement which will make representative Air Quality Information available on AirVoice open map" mdxType="NewsHeader" />
    <blank-space />
    <h3>{`Before the agreement was signed the parties conducted the research to compare the calculation results from EQOlabs and Airvoice Monitors measurements in Moscow and Saint Petersburg.  As a result the data from EQOlabs have shown great compliance rate to Airvoice measurements.`}</h3>
    <blank-space />
    <h3><strong parentName="h3">{`Dmitry Chubarov`}</strong>{`, researcher, head of Airvoice modelling group:`}</h3>
    <blockquote>
      <p parentName="blockquote">{`It’s very difficult to balance between model accuracy and computational complexity.
EQOlabs team has developed the technology which allows to receive detailed air
quality maps in hundreds of cities all over the world without using supercomputer
techniques.`}</p>
    </blockquote>
    <NewsImgWithDescription img="cityair-has-verified-air-quality-data-from-airquality.report-and-agreed-on-project-integration-into-the--airvoice-platform_1.jpg" description="The calculation results from EQOlabs and Airvoice Monitors measurements in Moscow and Saint Petersburg. As a result the data from EQOlabs have shown great compliance rate to Airvoice measurements. The diagram shows comparative calculations of one of the monitors." mdxType="NewsImgWithDescription" />
    <hr></hr>
    <blank-space />
    <h3><strong parentName="h3">{`Dmitry Trubitsyn`}</strong>{`, Airvoice Founder:`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Collaboration with AirQuality.report is a very important step to transform AirVoice from service based on Airvoice solutions into the platform which provides different types of services also through integration with Airvoice verified and approved providers. Airvoice project will further evolve as a place uniting different digital solutions based on reliable and verified air quality data. Moreover we will continue to develop on AirVoice Platform our own services and open map, deploying air quality monitoring networks all over the world.`}</p>
      <p parentName="blockquote">{`Already on the current stage AirVoice in quite simple terms may deliver Real Time air quality info and pollution data as well as provide full statistics based on seasonal and full research data. The data helps to care for health, making simple daily decisions: doing sports indoor or outdoor, open or close windows, etc. Integration with AirQuality.report with its high resolution air pollution services will provide AirVoice users an extra tool which can for example help to choose a more preferable eco region or city for living.`}</p>
    </blockquote>
    <hr></hr>
    <blank-space />
    <h3><strong parentName="h3">{`Serge Sokolov`}</strong>{`, EQOlabs founder:`}</h3>
    <blockquote>
      <p parentName="blockquote">{`We are happy to collaborate with Airvoice. Unification of our services will help to provide the users with most accurate and comprehensive air quality information to the user with the required level of specification. Air Quality Data transparency and completeness are becoming of greater demand among population.`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`About Airvoice`}</h2>
    <p>{`Engineering company, air quality monitoring hardware, software and platforms developer & producer.`}</p>
    <p>{`Airvoice platform for air quality solutions in urban and industrial sectors.`}</p>
    <p>{` Airvoice platform provides citizens with air quality data (real time air pollution level, eco profile of the city etc.) and its main objective — to digitalize the global air quality data and make the information comprehensible and easily accessible.`}</p>
    <h2>{`About EQOlabs`}</h2>
    <p>{`EQOlabs and its web portal AirQuality.report provides representative air quality data at any point of the city (resolution level — quarter or street). AirQuality Index is being calculated on historical data basis.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      