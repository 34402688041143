import React from 'react'

import './header.less'

export default function NewsHeader(props) {
    let imgBlock = null

    if (props.img) {
        const img = require(`../../assets/news/img/${props.img}`)

        imgBlock = (
            <div className="news_header__img_block">
                <img className="news_header__img" src={img.default} alt="" />
            </div>
        )
    }

    return (
        <div className="news_header">
            {/*<div className="news_header__tag">{props.tag}</div>*/}
            <div className="news_header__flex">
                <div>
                    <div className="news_header__title">{props.title}</div>
                    <div className="news_header__description">{props.description}</div>
                </div>
                {imgBlock}
            </div>
        </div>
    )
}
