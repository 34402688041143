import React from 'react'
import Layout from '../layout'

import './layout.less'

const LayoutNews = ({ children }) => {
    return (
        <Layout>
            <div className="layout-news">
                {children}
            </div>
        </Layout>
    )
}

export default LayoutNews
